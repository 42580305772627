/**
 * Combined by jsDelivr.
 * Original files:
 * - /gh/statground/web-r_CDN@master/scripts/common/menu/init_variables_20241004_0251.js
 * - /gh/statground/web-r_CDN@master/scripts/common/menu/Div_menu_20241004_0247.js
 * - /gh/statground/web-r_CDN@master/scripts/common/menu/click_dropdown_20241004_0250.js
 * - /gh/statground/web-r_CDN@master/scripts/common/menu/get_menu_header_20241004_0303.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
